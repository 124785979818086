var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Pagination, PaginationItem } from '@mui/material';
import { useMemo } from 'react';
import styled from '@emotion/styled';
import { getPageCount } from '@shared/ui/display/Table/helpers';
import PreviousNextButton from '../PreviousNextButton';
const Container = styled.div(props => ({
    display: 'flex',
    width: '100%',
    height: Math.round(props.theme.baseSize * 8.6),
    alignItems: 'center',
    justifyContent: 'end',
}));
const RenderPaginationItem = (_a) => {
    var { type, disabled, onClick } = _a, props = __rest(_a, ["type", "disabled", "onClick"]);
    if (type === 'previous' || type === 'next') {
        return (_jsx(PreviousNextButton, { next: type === 'next', onClick: onClick, disabled: disabled }));
    }
    return (_jsx(PaginationItem, Object.assign({ type: type, disabled: disabled, onClick: onClick }, props)));
};
const CustomPagination = ({ pageIndex, pageSize, totalRowsCount, onChange, }) => {
    const page = useMemo(() => pageIndex + 1, [pageIndex]);
    const pageCount = useMemo(() => getPageCount(totalRowsCount, pageSize), [totalRowsCount, pageSize]);
    const onChangeHandler = (e, p) => {
        onChange(p - 1);
    };
    return (_jsx(Container, { children: _jsx(Pagination, { color: 'primary', shape: 'rounded', page: page, count: pageCount, renderItem: RenderPaginationItem, onChange: onChangeHandler }) }));
};
export default CustomPagination;
